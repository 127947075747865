import React, { useEffect, useRef, useState } from "react";
import "./css/InventoryList.css";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Axios from "axios";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import AddInventoryAgainstBillingModel from "./AddInventory/AddInventory";
import UpdateInventoryAgainstBilllingModel from "./UpdateInventory/UpdateInventory";
import CommonLoaderBlue from "../../Loaders/components/Loader/LoaderBlue";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
export default function InventoryList({ billingModelSelect }) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let toast = useRef();
    const [refresh, setRefresh] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [statusChangeVisibility, setStatusChangeVisibility] = useState(false);
    const [addInventory, setAddInventory] = useState(false);

    const [inventorySelected, setInventorySelected] = useState();
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const [loader, setLoader] = useState(true);
    const [updateInventory, setUpdateInventory] = useState(false);
    const [InventoryList, setinventoryList] = useState([]);

    const updateBillingModelStatus = () => {
        setStatusLoading(true);
        Axios.put(`${BASE_URL}/api/inventoryType/statusUpdate?inventoryTypeId=${inventorySelected._id}&active=${inventorySelected?.active ? false : true}`)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Inventory Type Status Updation", detail: `Inventory Type ${inventorySelected?.active ? "Deactivated" : "Activated"} Successfully` });

                setTimeout(() => {
                    setStatusLoading(false);
                    setStatusChangeVisibility(false);
                    setRefresh((prev) => !prev);
                }, 500);
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Inventory Type Status Updation", detail: `Inventory Type ${inventorySelected?.active ? "Deactivation" : "Activation"} Failed` });
                setStatusLoading(false);
            });
    };
    useEffect(() => {
        Axios.get(`${BASE_URL}/api/inventoryType/all`, {
            params: {
                serviceProvider: parseLoginRes?.company,
                billingModel: billingModelSelect,
            },
        })
            .then((res) => {
                setinventoryList(res?.data?.data);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
            });
    }, [refresh]);
    return (
        <div>
            <div className="updaterestore">
                <Button
                    onClick={() => {
                        setAddInventory(true);
                    }}
                    className="text-center mr-0"
                    icon="pi pi-plus"
                    label="Add Inventory"
                />
            </div>
            <div className="inventoryListTableOverflow">
                <DataTable
                    value={InventoryList}
                    size="small"
                    className="inventoryListtable"
                    stripedRows
                    resizableColumns
                    emptyMessage={
                        loader ? (
                            <div className="flex flex-wrap flex-row justify-content-center align-items-center ">
                                <CommonLoaderBlue />
                            </div>
                        ) : (
                            "Inventory Type Not Found"
                        )
                    }
                    style={{ marginTop: "10px" }}
                >
                    <Column header="Inventory Type" field="inventoryType" />
                    <Column header="Make" field="make" />
                    <Column header="Model" field="model" />
                    <Column header="Selling Price" field="sellingPrice" />
                    <Column header="Discount" field="discount" />
                    <Column header="ProfitMargin" field="profitMargin" />

                    <Column header="Identifier" field="identifierType.identifier" />

                    <Column
                        header="Actions"
                        headerClassName="p-3"
                        body={(rowData) => {
                            return (
                                <div style={{ width: "250px" }} className="actionsbilling flex flex-wrap flex-row justify-content-left align-items-center gap-4">
                                    <Button
                                        label=""
                                        style={{ height: "25px", paddingTop: "0px !imprtant", paddingBottom: "0px !important" }}
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                            setInventorySelected(rowData);
                                            setUpdateInventory(true);
                                        }}
                                    />

                                    {!rowData?.active ? (
                                        <InputSwitch
                                            checked={false}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setInventorySelected(rowData);
                                                console.log("inventory selected is", rowData);
                                                setStatusChangeVisibility(true);
                                            }}
                                        />
                                    ) : (
                                        <InputSwitch
                                            checked={true}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setInventorySelected(rowData);
                                                console.log("inventory selected is", rowData);
                                                setStatusChangeVisibility(true);
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }}
                        field="Edit"
                    />
                </DataTable>
            </div>
            <Dialog
                style={{ width: "85vw" }}
                header="Update Inventory"
                visible={updateInventory}
                onHide={() => {
                    setUpdateInventory(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <UpdateInventoryAgainstBilllingModel billingModelSelect={billingModelSelect} inventorySelected={inventorySelected} />
            </Dialog>
            <Dialog
                style={{ width: "85vw" }}
                header="Add Inventory"
                visible={addInventory}
                onHide={() => {
                    setAddInventory(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <AddInventoryAgainstBillingModel setRefresh={setRefresh} setAddInventory={setAddInventory} billingModelSelect={billingModelSelect} />
            </Dialog>

            <Dialog
                header={inventorySelected?.active ? "Inventory Type Deactivation" : "Inventory Type Activation"}
                headerClassName="font-poppins text-main-color"
                visible={statusChangeVisibility}
                onHide={() => {
                    setStatusChangeVisibility((prev) => !prev);
                }}
            >
                <p className="text-main-color font-poppins">Do You Want To {inventorySelected?.active ? "Deactivate" : "Activate"} the Inventory Type ?</p>
                <div className="mt-4  changestatusdialogbutton flex font-poppins flex-wrap flex-row ">
                    <Button onClick={updateBillingModelStatus} loading={statusLoading} disabled={statusLoading} label="Yes" />

                    <Button
                        label="No"
                        onClick={() => {
                            setStatusChangeVisibility(false);
                        }}
                    />
                </div>
                <Toast ref={toast} />
            </Dialog>
        </div>
    );
}
