import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";   
import "./css/add_units_flowpage.css"
import Axios from "axios";
import { useLocation } from "react-router-dom";
import Header from "./add_unit-flow_page_header.js";
import CellPhoneSingleUpload from "./add_units_components/cell_phone_singleupload/cell_phone_singleupload.js";
import { type } from "./assets.js";
import TabletBulkUploadAddProvision from "./add_units_components/tablet_bulk_upload/add_stock_provision.js";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AddUnits = ({ setActiveComponent }) => {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const validationSchema = Yup.object().shape({
        unitType: Yup.string().required("please select"),
        uploadType: Yup.string().required("please select type "),
        makeModel:Yup.string().required("please select type ")
    });
    const formik = useFormik({
        initialValues: {
            unit: "",
            upload: "",
          makeModel:"",
            billingModel: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {},
    });
    const location = useLocation();
    const currentPath = location?.pathname;
    const [unitOptions, setUnitOptions] = useState([]);
    const [billingModelList, setBillingModelList] = useState([]);
    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);

        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };
    const [isManage, setIsManage] = useState(null);
    const [currentBillingId, setCurrentBillingId] = useState("");
    const [isCreate, setIsCreate] = useState(null);     
    const [makeModelOptions,setMakeModelOptions]=useState()  
    const [identifierTypeSelected,setIdentifierTypeSelected]=useState()
    useEffect(() => {
        actionBasedChecks();
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
            })
            .catch((err) => {});
    }, []);
    useEffect(() => {
        async function fetchData() {
            if (formik.values.billingModel !== "") {
                try {
                    const res = await Axios.get(`${BASE_URL}/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                    let obj = [];
                    let data = res?.data?.data;
                    data.forEach((item) => {
                        let obj2 = {};
                        obj2.inventoryType = item;
                        obj.push(obj2);
                    });
                    setUnitOptions(obj);
                } catch (error) {
                    //toast.error(error?.response?.data?.msg);
                }
            }
        }
        fetchData();
    }, [currentBillingId]);    
      useEffect(()=>{ 
         if(formik.values.unit !== "" && formik.values.billingModel !== ""){ 
            Axios.get(`${BASE_URL}/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.unit}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`).then(res=> { 
             console.log(res?.data?.data)   
             let Data=res?.data?.data      
              let dataNew=[] 
              for(let i=0;i<Data.length; i++){ 
                let data=Data[i] 
                data.makemodel=`${data.make} - ${data.model}` 
                dataNew.push(data)
              }  
              setMakeModelOptions(dataNew)
            }).catch(err=>{ 

            })
            
      
         }
      },[formik.values.billingModel,formik.values.unit]) 

   useEffect(()=>{ 
     if(formik.values.makeModel !== ""){
    for(let i=0;i<makeModelOptions.length;i++){ 
        if(makeModelOptions[i].makemodel === formik.values.makeModel){ 
            setIdentifierTypeSelected(makeModelOptions[i].identifierType?.identifier)
          console.log("identifier is",makeModelOptions[i].identifierType?.identifier)
        }
    } 
}
   },[formik.values.makeModel])
   useEffect(()=>{ 
   formik.setFieldValue("makeModel","")

   },[formik.values.unit])
    return (
        <>
            <div style={{ marginTop: "0px" }} className="heading">
                <Header unit={formik.values.unit} />
            </div>
            <div>
                <div className="flex flex-wrap mb-1 main-addunit   justify-content-left">
                    <div className="mainaddunitfield">
                        <p className="field_label">Billing Model</p>
                        <Dropdown
                            value={formik.values.billingModel}
                            name="billingModel"
                            optionLabel="billingModel"
                            optionValue="billingModel"
                            options={billingModelList}
                            onChange={(e) => {
                                let id;
                                billingModelList.map((item) => {    
                                    formik.setFieldValue("unit","")
                                    if (item.billingModel === e.value) {
                                        id = item._id;
                                    }
                                });  
                                  
                                formik.setFieldValue("billingModel", e.value);
                                  formik.handleChange(e)
                                setCurrentBillingId(id);
                            }}
                            placeholder="Select an option"
                            className="w-full "
                        />
                    </div>
                    <div className="mainaddunitfield">
                        <p className="field_label ">Inventory Type</p>
                        <Dropdown optionLabel="inventoryType" optionValue="inventoryType" value={formik.values.unit} name="unit" options={unitOptions} onChange={formik.handleChange} placeholder="Select an option" className="w-full" />
                    </div>  
                    <div className="mainaddunitfield">
                        <p className="field_label ">Make And Model</p>
                        <Dropdown optionLabel="makemodel" optionValue="makemodel" value={formik.values.makeModel} name="makeModel" options={makeModelOptions} onChange={formik.handleChange} placeholder="Select an option" className="w-full " />
                    </div>

                    <div className="mainaddunitfield">
                        <p className="field_label">
                            Upload Type <span style={{ color: "red" }}>*</span>
                        </p>
                        <Dropdown value={formik.values.upload} name="upload" options={type} onChange={formik.handleChange} placeholder="Select an option" className="w-full" />
                    </div>
                  
                </div>
            </div>
      {
                formik.values.upload === "Single" && formik.values.unit !== "" && formik.values.makeModel !== ""  ? 
                    <CellPhoneSingleUpload  
                    setActiveComponent={setActiveComponent} 
                    identifierTypeSelected={identifierTypeSelected} unit={formik.values.unit} model={formik.values.billingModel} makeModel={formik.values.makeModel} permissions={{ isCreate: isCreate }} />
                 : formik.values.upload === "Bulk" && formik.values.unit !== "" && formik.values.makeModel !== "" ? 
                    <TabletBulkUploadAddProvision  
                    setActiveComponent={setActiveComponent}  
                    makeModel={formik.values.makeModel}  identifierTypeSelected={identifierTypeSelected} unit={formik.values.unit} model={formik.values.billingModel} permissions={{ isCreate: isCreate }} />
                :undefined}
           
        </>
    );
};

export default AddUnits;
