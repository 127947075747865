import "./css/QuickLinks.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function QuickLinks({ expand, cpData }) {

    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [quickLinks, setQuickLinks] = useState([
        {
            label: "Wifi Calling",
            icon: "pi pi-wifi",
            navigator: "/Wifi-Calling",
            onClick: () => navigate("/Wifi-Calling"),
        },
        {
            label: "Change Address",   
            icon:"pi pi-map",
            onClick: () => setShowDialog(true),
        },
        {
            label: "Query Network",   
             icon:"pi-globe pi",
            onClick: () => {
                setIsLoading(false);
                navigate("/Query_Network");
            },
        },
        {
            label: "Query SIM",   
            icon:"pi-globe pi",
            onClick: () => {
                setIsLoading(false);
                navigate("/Query_Sim");
            },
        },          
        {
            label: "Imei Validation",   
            icon:"pi-globe pi",
            onClick: () => {
                setIsLoading(false);
                navigate("/Imei_Validation");
            },
        },     
    ]);

    const formik = useFormik({
        initialValues: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
        },
        onSubmit: async (values, actions) => {
            // Form submission logic here
        },
    });

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    useEffect(() => {
        formik.setFieldValue("address1", cpData?.address1);
        formik.setFieldValue("address2", cpData?.address2);
        formik.setFieldValue("city", cpData?.city);
        formik.setFieldValue("state", cpData?.state);
        formik.setFieldValue("zip", cpData?.zip);
    }, [cpData]);

    const handleYesClick = async () => {
        setIsLoading(true);
        const dataToSend = {
            userId: parseLoginRes?._id,
            enrollmentId: cpData?._id,
            address1: formik.values.address1,
            address2: formik.values.address2,
            city: formik.values.city,
            state: formik.values.state,
            zip: formik.values.zip,
        };
        try {
            const resp = await Axios.post(`${BASE_URL}/api/user/updateE119Address`, dataToSend);
            if (resp?.status === 200 || resp?.status === 201) {
                toast.success(resp?.data?.msg);
                setIsLoading(false);
                setShowDialog(false);
            }
        } catch (error) {
            toast.error(`${error?.response?.data?.msg}: ${error?.response?.data?.status?.errors?.error?.message}`);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const handleNoClick = async () => {
        setShowDialog(false);
    };

    return (
        <>
            <ToastContainer />

            <div className="card quick-link-main">
                {quickLinks?.map((item, index) => (
                    <div key={index} className="flex quick-link-item flex-wrap flex-row justify-content-left" onClick={item.onClick}>
                        <i className={`${item?.icon} mb-3`} />
                        <p className="mb-3">{item?.label}</p>
                    </div>
                ))}
            </div>

            <Dialog
                visible={showDialog}
                style={{ width: "70vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleYesClick} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDialog(false)}
            >
                <div className="flex p-fluid formgrid grid">
                    <div className="">
                        <p>MDN</p>
                        <InputText type="text" className="w-21rem mr-3" disabled value={cpData?.portedMDN} id="mdn" />
                    </div>
                    <div className="">
                        <p>ESN</p>
                        <InputText type="text" className="w-21rem mr-3" disabled value={cpData?.esn} id="esn" />
                    </div>
                    <div className="">
                        <p>Address 1</p>
                        <InputText type="text" className="w-21rem mr-3" value={formik.values.address1} id="address1" onChange={formik.handleChange} />
                    </div>
                    <div className="">
                        <p>Address 2</p>
                        <InputText type="text" className="w-21rem mr-2" value={formik.values.address2} id="address2" onChange={formik.handleChange} />
                    </div>
                    <div className="mt-2">
                        <p>City</p>
                        <InputText type="text" className="w-21rem mr-3" value={formik.values.city} id="city" onChange={formik.handleChange} />
                    </div>
                    <div className="mt-2">
                        <p>State</p>
                        <InputText type="text" className="w-21rem mr-3" value={formik.values.state} id="state" onChange={formik.handleChange} />
                    </div>
                    <div className="mt-2">
                        <p>Zip</p>
                        <InputText type="text" className="w-21rem" value={formik.values.zip} id="zip" onChange={formik.handleChange} keyfilter={/^\d{0,5}$/} maxLength={5} />
                    </div>
                </div>
            </Dialog>
        </>
    );
}
