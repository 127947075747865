import React, { useRef, useState } from "react";    
import "./Imei_validation.css"     
import { useFormik } from "formik";    
import * as Yup from "yup"   
import Axios from "axios"
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";       
import {Toast} from "primereact/toast"
  
export default function ImeiVaidation(){            
     let toast=useRef()
      const [loader,setLoader]=useState(false)   
     const formik=useFormik({ 
          initialValues:{ 

          }, 
           validationSchema:Yup.object({
               imei: Yup.string().required("Imei is required"),
           }),
           onSubmit:(values,{resetForm})=>{      
                 setLoader(true)
               Axios.post(`${process.env.REACT_APP_BASE_URL}/user/validateImei`, formik.values)
               .then((response) => {       
                    setLoader(false)     
                    
            toast.current.show({ severity: "success", summary: "IMEI Validation", detail: <p>IMEI Validated Successfully</p>});


                }).catch(err=>{ 
                    setLoader(false)         
                    toast.current.show({ severity: "error", summary: "IMEI Validation", detail: <p>IMEI Validation Failed</p>});

               })
           }
     })
     return( 
          <form  onSubmit={formik.handleSubmit} className="card main-imei-validation w-full">        
               <div className="mainvalidateflex  w-full flex flex-wrap flex-row justify-content-start">   
                    <i className="pi pi-globe"/>
               <h1 >IMEI Validation</h1>   
               </div>        
                  <div className="w-full flex flex-wrap flex-row justify-content-center">
               <div className="imeiinputtbox ">  
                     <label className="field_label ">IMEI <span className="asterisk ">*</span></label>   
                      <InputText     
                      name="imei" 
                      onChange={formik.handleChange} 
                      keyfilter="int"     
                      value={formik.values.imei} 
                         className="mt-4 w-full"
                       />    
                           {formik.touched.imei && formik.errors.imei ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1  mt-0 ">
                                        {formik.errors.imei}
                                    </p>
                                ) : null}
                        <Button disabled={loader}  loading={loader} label="Submit" type="submit"  className="mt-4" />
               </div>                          
               </div>
                  <Toast ref={toast} />
          </form>
     )
}