import React, { useRef, useState } from "react" 
import "./css/addBillingModelInventory.css"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"  
import { useFormik } from "formik"
import * as Yup from "yup"    
import { useEffect } from "react"  
import Axios from "axios"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog" 
import { Toast } from "primereact/toast"
export default function UpdateInventoryAgainstBillingmode({billingModelSelect, inventorySelected}){   
  console.log("Inventory Selectd is",inventorySelected)  
    const [imgsrc,setimgsrc]=useState()        
    const [selectedBillingModel,setSelectedBillingModel]=useState("")  
    let toast=useRef()
     const [loader,setLoader]=useState(false)                 
     const Imgref=useRef()
     const parseLoginRes=JSON.parse(localStorage.getItem("userData"))
    const validationSchema=Yup.object().shape({ 
      billingModel:selectedBillingModel, 
        make: Yup.string()
        .required("Make is required")
        .matches(/^[^-]*$/, "Hyphens are not allowed"),    
        inventoryType:Yup.string().required("Inventory Type Required"),
        model:Yup.string()
        .required("Model is required")
        .matches(/^[^-]*$/, "Hyphens are not allowed"),
        length: Yup.number().required("Length Is Required"),
        width: Yup.number().required("Width Is Required"),
        weightounce: Yup.number().required("Weight Is Required"),
        weight: Yup.number().required("Weight Is Required"),
        ShipFromaddress1: Yup.string().required("Ship From Is Required"),
        height: Yup.number().required("Height Is Required"),   
        identifierType:Yup.string().required("Identifier Type Is Required"),   
         city:Yup.string().required("City Is Required"),   
         state:Yup.string().required("State Is Required"),     
        zipCode:Yup.number().required("Zip Code Is Required"),  
        costPrice:Yup.number().required("Cost Price Is Required"),  
          
        sellingPrice:Yup.number().required("Selling Price Is Required"),    
          
        profitMargin:Yup.number().required("Profit Margin  Is Required"),  
        discount: Yup.number().required("Discount  Is Required"),      
        billingModel:Yup.string().required("Billing Model Is Required")
           
      })       
       const [identifierList,setIdentifierList]=useState([])   
       const BASE_URL = process.env.REACT_APP_BASE_URL;
      useEffect(() => {
        Axios.get(`${BASE_URL}/api/identifier/getAll?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setIdentifierList(res?.data?.data)
          
            })
            .catch((err) => {});
    }, []);
      const formik = useFormik({
        initialValues: {
            make: inventorySelected?.make, 
            model:inventorySelected?.model,   
            inventoryType:inventorySelected?.inventoryType, 
            length: inventorySelected?.length,
        width: inventorySelected?.width,
     weightounce:inventorySelected?.weight?.lbs, 
     weight:inventorySelected?.weight?.oz,
            ShipFromaddress1: inventorySelected?.ShipFrom?.address, 
            ShipFromaddress2:inventorySelected?.ShipFrom?.address2,
            height:inventorySelected?.height,   
            identifierType:inventorySelected?.identifierType?._id,   
             city:inventorySelected?.ShipFrom?.city,   
             state:inventorySelected?.ShipFrom?.state,     
            zipCode:inventorySelected?.ShipFrom?.zipCode,    
            costPrice:inventorySelected?.costPrice,  
              
            sellingPrice:inventorySelected?.sellingPrice,    
              
            profitMargin:inventorySelected?.profitMargin,  
            discount: inventorySelected?.discount,   
            image:"",    
            billingModel:billingModelSelect, 
             serviceProvider:parseLoginRes?.company
        },  validationSchema,
        onSubmit: (values, {resetForm}) => { 
           
                setLoader(true)  
                let formData=new FormData()   
                console.log(formik.values)
                  Object.keys(formik.values).forEach(item=>{ 
        if(item === "weightounce"){ 
              formData.append("weight.oz",formik.values[item])
         } 
         else if(item === "weight"){ 
          formData.append("weight.lbs",formik.values[item])
         } 
         else if(item === "ShipFromaddress1" || item === "city" || item === "state" || item === "zipCode" || item === "ShipFromaddress2"){ 
               if(item === "ShipFromaddress1"){ 
                formData.append("ShipFrom.address",formik.values[item])
               } 
               else if(item === "city"){ 
                formData.append("ShipFrom.city",formik.values[item])
               } 
               else if( item === "state"){ 
                formData.append("ShipFrom.state",formik.values[item])
               } 
               else if(item === "zipCode"){ 
                formData.append("ShipFrom.zipCode",formik.values[item])
               } 
               else{ 
                formData.append("ShipFrom.address2",formik.values[item])
               }
         } 
         else{ 
          formData.append(item,formik.values[item])
         }
               
        
        })          
       
          Axios.put(`${BASE_URL}/api/inventoryType/updateInventory?serviceProvider=${parseLoginRes?.company}`,formData,{params:{ id:inventorySelected?._id}})
              .then((res) => {
                toast.current.show({ severity: "success", summary: "Inventory Type", detail:"Inventory Type Updated Successfully" })
                   setLoader(false)       
       Imgref.current.value=""
               
              })
              .catch((err) => { 
                toast.current.show({ severity: "error", summary: "Inventory Type", detail:"Inventory Type Updation Failed" })
                setLoader(false)
              });

            

        },  
    })      
    const [billingModelList, setBillingModelList] = useState([]);

    const [refreshBillingModel,setRefreshBillingModel]=useState(false)
    useEffect(() => {
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
                //setLoader(false);
            })
            .catch((err) => {});
    }, [refreshBillingModel]);   
    useEffect(()=>{  
      if(formik.values.sellingPrice !== "" && formik.values.discount !== "" && formik.values.costPrice !== ""){ 
       formik.setFieldValue("profitMargin",((formik.values.sellingPrice-formik.values.discount)-formik.values.costPrice))
      }  
      else{ 
        
       formik.setFieldValue("profitMargin",0)
      }

    },[formik.values.sellingPrice,formik.values.discount,formik.values.costPrice])
     return(    
      <div>   
       
        <form onSubmit={formik.handleSubmit} className="mainAddInventory" >      
          <div className="maininputtextcontainer">  
            <label className="w-full">Inventory Type</label>
              <InputText  value={formik.values.inventoryType} name="inventoryType" onChange={formik.handleChange} className="mt-2 w-full" placeholder="Inventory Type" />   
              {formik.touched.inventoryType && formik.errors.inventoryType ? (
              <p className="p-error mt-1">{formik.errors.inventoryType}</p>
            ) : undefined}
         </div>     
         <div className="maininputtextcontainer">  
            <label className="w-full">Make</label>
              <InputText  value={formik.values.make} name="make" onChange={formik.handleChange} className="mt-2 w-full" placeholder="make" />   
              {formik.touched.make && formik.errors.make ? (
              <p className="p-error mt-1">{formik.errors.make}</p>
            ) : undefined}
         </div>  
         <div className="maininputtextcontainer">  
            <label className="w-full">Model</label>
              <InputText value={formik.values.model}  name="model" onChange={formik.handleChange} className="mt-2 w-full" placeholder="model" />    
              {formik.touched.model && formik.errors.model ? (
              <p className="p-error mt-1">{formik.errors.model}</p>
            ) : undefined}
         </div>    
     

         <div className="maininputtextcontainer">  
            <label className="w-full">Length (in)</label>
              <InputText keyfilter="num" value={formik.values.length} name="length" onChange={formik.handleChange} className="mt-2 w-full" placeholder="length" />     
              {formik.touched.length && formik.errors.length ? (
              <p className="p-error mt-1">{formik.errors.length}</p>
            ) : undefined}
         </div>   
         <div className="maininputtextcontainer">  
            <label className="w-full">Width (in)</label>
              <InputText keyfilter="num"   value={formik.values.width} name="width" onChange={formik.handleChange}  className="mt-2 w-full" placeholder="width" /> 
              {formik.touched.width && formik.errors.width ? (
              <p className="p-error mt-1">{formik.errors.width}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Height (in)</label>
              <InputText keyfilter="num" value={formik.values.height} name="height" onChange={formik.handleChange} placeholder="height" className="mt-2 w-full"  />    
              {formik.touched.height && formik.errors.height ? (
              <p className="p-error mt-1">{formik.errors.height}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Weight (lbs)</label>
              <InputText keyfilter="num" name="weight"  value={formik.values.weight} onChange={formik.handleChange}   className="mt-2 w-full"  placeholder="Weight" />
              {formik.touched.weight && formik.errors.weight ? (
              <p className="p-error mt-1">{formik.errors.weight}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Weight (Oz)</label>
              <InputText keyfilter="num" name="weightounce" value={formik.values.weightounce} onChange={formik.handleChange}  className="mt-2 w-full" placeholder="Weight" /> 
              {formik.touched.weightounce && formik.errors.weightounce ? (
              <p className="p-error mt-1">{formik.errors.weightounce}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">          
           <div className="w-full identifierTypemain flex flex-wrap flex-row justify-content-left align-items-center">
            <label >Identifier Type</label>   
          
             </div>
              <Dropdown optionLabel="identifier" optionValue="_id" value={formik.values.identifierType} name="identifierType" onChange={formik.handleChange} className="mt-2 w-full" options={identifierList} placeholder="Identifier Type" />  
              {formik.touched.identifierType && formik.errors.identifierType ? (
              <p className="p-error mt-1">{formik.errors.identifierType}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Ship From</label>
              <InputText className="mt-2 w-full" value={formik.values.ShipFromaddress1}  name="ShipFromaddress1" onChange={formik.handleChange} placeholder="Street Address" /> 
              {formik.touched.ShipFromaddress1 && formik.errors.ShipFromaddress1 ? (
              <p className="p-error mt-1">{formik.errors.ShipFromaddress1}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Ship From (Address2)</label> 

              <InputText name="ShipFromaddress2" value={formik.values.ShipFromaddress2} onChange={formik.handleChange} className="mt-2 w-full"  placeholder="Address2" /> 
              {formik.touched.ShipFromaddress2 && formik.errors.ShipFromaddress2 ? (
              <p className="p-error mt-1">{formik.errors.ShipFromaddress2}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">City</label>
              <InputText className="mt-2 w-full"  value={formik.values.city}  placeholder="city" name="city" onChange={formik.handleChange} />        
              {formik.touched.city && formik.errors.city ? (
              <p className="p-error mt-1">{formik.errors.city}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">State</label>
              <InputText name="state"  value={formik.values.state} onChange={formik.handleChange} className="mt-2 w-full"  placeholder="state" /> 
              {formik.touched.state && formik.errors.state ? (
              <p className="p-error mt-1">{formik.errors.state}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Zip Code</label>
              <InputText keyfilter="int"  name="zipCode" value={formik.values.zipCode}  onChange={formik.handleChange} className="mt-2 w-full" placeholder="Zip Code" /> 
              {formik.touched.zipCode && formik.errors.zipCode ? (
              <p className="p-error mt-1">{formik.errors.zipCode}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full" >Cost Price</label>
              <InputText keyfilter="num" name="costPrice" value={formik.values.costPrice}  onChange={formik.handleChange}  className="mt-2 w-full"  placeholder="Cost Price"/> 
              {formik.touched.costPrice && formik.errors.costPrice ? (
              <p className="p-error mt-1">{formik.errors.costPrice}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Selling Price</label>
              <InputText keyfilter="num" onChange={formik.handleChange}  value={formik.values.sellingPrice} name="sellingPrice"  className="mt-2 w-full" placeholder="Selling Price" /> 
              {formik.touched.sellingPrice && formik.errors.sellingPrice ? (
              <p className="p-error mt-1">{formik.errors.sellingPrice}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Discount</label>
              <InputText keyfilter="num"  name="discount" value={formik.values.discount}  onChange={formik.handleChange} className="mt-2 w-full" placeholder="discount"  /> 
              {formik.touched.discount && formik.errors.discount ? (
              <p className="p-error mt-1">{formik.errors.discount}</p>
            ) : undefined}
         </div>
         <div className="maininputtextcontainer">  
            <label className="w-full">Profit Margin</label>
              <InputText disabled keyfilter="num"  name="profitMargin" value={formik.values.profitMargin} onChange={formik.handleChange} className="mt-2 w-full"  placeholder="Profit Margin"/>
              {formik.touched.profitMargin && formik.errors.profitMargin ? (
              <p className="p-error mt-1">{formik.errors.profitMargin}</p>
            ) : undefined}
         </div>   
         <div className="maininputtextcontainer">  
            <label className="w-full">Image</label>
              <InputText ref={Imgref} name="image"  type="file" onChange={(e)=>{ 
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{  
                    setimgsrc(reader.result)
                }   
                formik.setFieldValue("image",e.target.files[0])
              }} className="mt-2 w-full"  placeholder="Profit Margin"/>  
                 {formik.touched.image && formik.errors.image ? (
              <p className="p-error mt-1">{formik.errors.image}</p>
            ) : undefined}
         </div>     
          {  
          imgsrc || inventorySelected?.image ?
          <div className="imageselected"> 
            <img src={imgsrc ? imgsrc :inventorySelected?.image ? `${BASE_URL}/inventory/${inventorySelected?.image}`:""}  
            />
          </div> :undefined
}  
<div className="w-full">
 <Button  className="submitbillingconfig mt-4" disabled={loader} loading={loader} label="Submit" type="submit"/> 
  </div>
 </form>   
   
            <Toast ref={toast}/>
  </div>

     )
}