import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import "./css/plan_configuration.css";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { MultiSelect } from "primereact/multiselect";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name Is Required"),
    description: Yup.string().required("Description Is Required"),
    dataAllowance: Yup.number().integer("Please enter a valid integer").required("Data Allowance Is Required "),
    voiceAllowance: Yup.number().integer("Please enter a valid integer").required("Voice Allowance Is Required"),
    textAllowance: Yup.number().integer("Please enter a valid integer").required("Text Allowance Is Required"),
    duration: Yup.number().integer("Please enter a valid integer").required("Duration Is Required"),
    price: Yup.number().required("Price Is Required"),
    planId: Yup.string().required("Plan Id Is Required"),
    type: Yup.string().required("Plan Type Is Required"),
    carrier: Yup.string().required("Carrier Is Required"),

    durationUnit: Yup.string().required("Duration Unit Is Required"),
    inventoryType: Yup.string().required("Inventory Type Is Required"),   
    makeModel:Yup.string().required("Make And Model Is Required")
});

export default function PlansConfigurations({ setAddPlanVisibility, setRefresh }) {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [carrier, setCarrier] = useState(null);
    const [currentBillingId, setCurrentBillingId] = useState("");
    const toast = useRef(null);
    const [inventoryTypeOptions, setInventoryTypeOptions] = useState([]);
    const [billingModelOptions, setBillingModelOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getBillingModelList = async () => {
        try {
            const res = await Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`);
            setBillingModelOptions(res?.data?.data || []);
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Plan Updation", detail: error?.response?.data?.msg });
        }
    };
    useEffect(() => {
        getBillingModelList();
    }, []);
    const isUnlimitedOptions = [
        {
            label: "Voice",
        },
        {
            label: "Text",
        },
        { label: "Data" },
    ];
    const durationUnitOptions = [
        {
            label: "Days",
            value: "days",
        },
    ];
    const [featureOptions, setFeatureOptions] = useState([
        {
            label: "3 Way Calling",
        },
        {
            label: "WI-FI Calling",
        },
        {
            label: "International Roaming",
        },
        {
            label: "5G Compatibility & Tethering Bloc",
        },
    ]);
    const formik = useFormik({
        initialValues: {
            name: "",
            createdBy: parseLoginRes?._id,
            serviceProvider: parseLoginRes?.company,
            description: "",
            carrier: "",
            type: "",
            durationUnit: "",
            dataAllowance: null,
            voiceAllowance: null,
            textAllowance: null,
            duration: null,
            price: "",
            planFeature: [],
            isUnlimited: [],  
            makeModel:"",
            planId: "",
            additionalFeatures: ["calls", "minutes"],
            termsAndConditions: "no termsAndConditions",
            restrictions: ["no restriction"],
            inventoryType: "",
        },
        validationSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            let selectedFeatures = [];
            featureOptions.forEach((item) => {
                console.log(item);
                let obj = {
                    name: item?.label,
                    isSelected: formik.values.planFeature.includes(item?.label) ? true : false,
                };
                selectedFeatures.push(obj);
            });      
            let make=formik.values.makeModel.substring(0,formik.values.makeModel.indexOf("-")-1)   
            let model=formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-")+2)   
            let dataTosend = { ...formik.values, planFeature: selectedFeatures,make:make,model:model };    
            delete dataTosend.makeModel
            Axios.post(`${BASE_URL}/api/web/plan`, dataTosend)
                .then(() => {
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Plan Submission", detail: "Plan Added Successfully" });
                    setTimeout(() => {
                        setAddPlanVisibility(false);
                        setRefresh((prev) => !prev);
                    }, 2000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Plan Submission", detail: err?.response?.data?.msg });
                });
        },
    });
    useEffect(() => {
        async function fetchData() {
            if (formik.values.type !== "") {
                try {
                    const res = await Axios.get(`${BASE_URL}/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                    let obj = [];
                    let data = res?.data?.data;
                    data.forEach((item) => {
                        let obj2 = {};
                        obj2.inventoryType = item;
                        obj.push(obj2);
                    });
                    setInventoryTypeOptions(obj);
                } catch (error) {
                    //toast.error(error?.response?.data?.msg);
                }
            }
        }
        fetchData();
    }, [currentBillingId]);     
    
    const [makeModelOptions,setMakeModelOptions]=useState()  
    useEffect(()=>{ 
        if(formik.values.inventoryType !== "" && currentBillingId !== ""){ 
           Axios.get(`${BASE_URL}/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`).then(res=> { 
            console.log(res?.data?.data)   
            let Data=res?.data?.data      
             let dataNew=[] 
             for(let i=0;i<Data.length; i++){ 
               let data=Data[i] 
               data.makemodel=`${data.make} - ${data.model}` 
               dataNew.push(data)
             }  
             setMakeModelOptions(dataNew)
           }).catch(err=>{ 

           })
           
     
        }
     },[currentBillingId,formik.values.inventoryType]) 
    useEffect(() => {
        Axios.get(`${BASE_URL}/api/web/carrier/all`)
            .then((res) => {
                let carrierholder = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    const obj = {};
                    obj.label = res.data.data[i].name;
                    obj.value = res.data.data[i]._id;
                    carrierholder.push(obj);
               
                }      
                if (carrierholder.length === 1) {
                    formik.setFieldValue("carrier", carrierholder[0].value);
                }
                setCarrier(carrierholder);
            })
            .catch(() => {});
    }, []);

    return (
        <div>
            <div>
                <form onSubmit={formik.handleSubmit} className="mainaddplan flex flex-wrap  flex-row justify-content-start">
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Billing Model <span className="star">*</span>
                        </label>
                        <Dropdown
                            placeholder="Plan Type"
                            options={billingModelOptions}
                            optionLabel="billingModel"
                            optionValue="billingModel"
                            className="mt-2 w-full"
                            name="type"
                            value={formik.values.type}
                            onChange={(e) => {
                                formik.setFieldValue("type", e.value);
                                let id;
                                billingModelOptions.map((item) => {
                                    if (item.billingModel === e.value) {
                                        id = item._id;
                                    }
                                });
                                setCurrentBillingId(id);
                            }}
                        />
                        {formik.touched.type && formik.errors.type ? <p className="mt-2 ml-1 star">{formik.errors.type}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Inventory Type <span className="star">*</span>
                        </label>
                        <Dropdown placeholder="Plan Inventory Type" options={inventoryTypeOptions} optionLabel="inventoryType" optionValue="inventoryType" className="mt-2 w-full" name="inventoryType" value={formik.values.inventoryType} onChange={formik.handleChange} />
                        {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="mt-2 ml-1 star">{formik.errors.inventoryType}</p> : null}
                    </div>     
                    <div className="addplanfield mt-2">
                        <label className="block">Make And Model</label>
                        <Dropdown optionLabel="makemodel" optionValue="makemodel" value={formik.values.makeModel} name="makeModel" options={makeModelOptions} onChange={formik.handleChange} placeholder="Select an option" className="mt-2 w-full " />      
                        {formik.touched.makeModel && formik.errors.makeModel ? <p className="mt-2 ml-1 star">{formik.errors.makeModel}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Carrier <span className="star">*</span>
                        </label>
                        <Dropdown value={formik.values.carrier} options={carrier} optionLabel="label" name="carrier" optionValue="value" onChange={formik.handleChange} placeholder="Select an option" className="w-full mt-2 " />
                        {formik.errors.carrier && formik.touched.carrier && (
                            <div className="mt-2" style={{ color: "red" }}>
                                {formik.errors.carrier}
                            </div>
                        )}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Name <span className="star">*</span>
                        </label>
                        <InputText
                            placeholder="Plan Name"
                            className="mt-2 w-full"
                            name="name"
                            value={formik.values.name}
                            onChange={(e) => {
                                formik.setFieldValue("name", capitalizeFirstLetter(e.target.value));
                            }}
                        />
                        {formik.touched.name && formik.errors.name ? <p className="mt-2 ml-1 star">{formik.errors.name}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Description <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan Description" className="mt-2 w-full" name="description" value={formik.values.description} onChange={formik.handleChange} />
                        {formik.touched.description && formik.errors.description ? <p className="mt-2 ml-1 star">{formik.errors.description}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan ID <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan ID" className="mt-2 w-full" name="planId" value={formik.values.planId} onChange={formik.handleChange} />
                        {formik.touched.planId && formik.errors.planId ? <p className="mt-2 ml-1 star">{formik.errors.planId}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Unlimited <span className="star">*</span>
                        </label>

                        <MultiSelect className="w-full mt-2  " placeholder="Select Unlimited" optionLabel="label" options={isUnlimitedOptions} optionValue="label" name="isUnlimited" onChange={formik.handleChange} value={formik.values.isUnlimited} />
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">Features</label>

                        <MultiSelect className="w-full mt-2  " placeholder="Select Features" optionLabel="label" options={featureOptions} optionValue="label" name="planFeature" onChange={formik.handleChange} value={formik.values.planFeature} />
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Data Allowance <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan Data Allowance" keyfilter="int" className="mt-2 w-full" name="dataAllowance" value={formik.values.dataAllowance} onChange={formik.handleChange} />
                        {formik.touched.dataAllowance && formik.errors.dataAllowance ? <p className="mt-2 ml-1 star">{formik.errors.dataAllowance}</p> : null}
                    </div>

                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Voice Allowance <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan Voice Allowance" keyfilter="int" className="mt-2 w-full" name="voiceAllowance" value={formik.values.voiceAllowance} onChange={formik.handleChange} />
                        {formik.touched.voiceAllowance && formik.errors.voiceAllowance ? <p className="mt-2 ml-1 star">{formik.errors.voiceAllowance}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Text Allowance <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan Text Allowance" keyfilter="int" className="mt-2 w-full" name="textAllowance" value={formik.values.textAllowance} onChange={formik.handleChange} />
                        {formik.touched.textAllowance && formik.errors.textAllowance ? <p className="mt-2 ml-1 star">{formik.errors.textAllowance}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Duration Unit <span className="star">*</span>
                        </label>
                        <Dropdown options={durationUnitOptions} optionLabel="label" optionValue="value" placeholder="Duration Unit" className="mt-2 w-full" name="durationUnit" value={formik.values.durationUnit} onChange={formik.handleChange} />
                        {formik.touched.durationUnit && formik.errors.durationUnit ? <p className="mt-2 ml-1 star">{formik.errors.durationUnit}</p> : null}
                    </div>
                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Duration <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan Duration" keyfilter="int" className="mt-2 w-full" name="duration" value={formik.values.duration} onChange={formik.handleChange} />
                        {formik.touched.duration && formik.errors.duration ? <p className="mt-2 ml-1 star">{formik.errors.duration}</p> : null}
                    </div>

                    <div className="addplanfield mt-2">
                        <label className="block">
                            Plan Price <span className="star">*</span>
                        </label>
                        <InputText placeholder="Plan Price" keyfilter="num" className="mt-2 w-full" name="price" value={formik.values.price} onChange={formik.handleChange} />
                        {formik.touched.price && formik.errors.price ? <p className="mt-2 ml-1 star">{formik.errors.price}</p> : null}
                    </div>
                    <div className="w-full flex flex-wrap flex-row justify-content-end mt-2">
                        <Button loading={isLoading} disabled={isLoading} className="field-width btn" label="Add Plan" type="Submit" />
                    </div>
                </form>
            </div>
            <Toast ref={toast} />
        </div>
    );
}
function capitalizeFirstLetter(string) {
    return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
