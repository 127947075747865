import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import "./style/stripe_payment_form.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "primereact/button";
import Axios from "axios";
import CommonLoaderBlue from "../../Loaders/Loader/LoaderBlue";
//const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function PaymentStripeForm({ setPayinvoicesVisible, setShowStripe, setRefresh, clientSecret, cpData, amount, selectedInvoices }) {

    const submitbuttonref = useRef(null)
    const stripe = useStripe();
    const loginRes = JSON.parse(localStorage.getItem("userData"))
    const [disableSubmit, setDisableSubmit] = useState(false);
    const toast = useRef(null);
    const elements = useElements();
    const [loading,setIsLoading]=useState(true)
    const handleCardReady = () => {
        setIsLoading(false);
    };

    const handleSubmit = async (event) => {

        submitbuttonref.current.style.opacity = "0.5"

        setDisableSubmit(true);
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (error) {

             toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message:"An error occurred while processing the payment"}</p> });
            setTimeout(() => {
                setShowStripe(false)
            }, 2000)
            const idArray = selectedInvoices.map(obj => obj._id);
            const invoiceTypeArray = selectedInvoices.map(data => data.invoiceType);
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                "customerId": cpData?._id,
                "invoiceNo": idArray,
                "invoiceType": invoiceTypeArray,
                "paymentMethod": "Credit Card",
                "stripeId": error?.payment_intent?.id,
                action: "failure",  
                cardNumber:error?.payment_method?.card?.last4,
                reason: "failure",
                transactionStatus: error?.message,
                paymentStatus: "failure",
                userId: loginRes?._id,
                "amount": amount
            }).then((res) => {
                // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });

            }).catch(err => {
                //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });

            })
        } else {         
            console.log("payment intent is",paymentIntent)
            const idArray = selectedInvoices.map(obj => obj._id);
            const invoiceTypeArray = selectedInvoices.map(data => data.invoiceType);  
            toast.current.show({ severity: "success", summary: "Payment Proceeding", detail: "Payment Proceed Successfully" });
               
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                "customerId": cpData?._id,
                "invoiceNo": idArray,
                "invoiceType": invoiceTypeArray,
                "paymentMethod": "Credit Card",
                "stripeId": paymentIntent?.id,
                userId: loginRes?._id,
                action: "success",
                reason: "success",
                transactionStatus: paymentIntent?.status,
                paymentStatus: "success",
                "amount": amount
            }).then((res) => {
                // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });

            }).catch(err => {
                ////toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });

            })

            Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                invoices: idArray,
                amountPaid: amount,
                invoicePaymentMethod: "Credit Card",
                paymentId: paymentIntent?.id,
                customerId: cpData?._id,
                userId: loginRes?._id,
                isWallet: false
            }).then(() => {
                // toast.current.show({ severity: "success", summary: "Invoices Updation", detail: "Invoices Updated Successfully" });

                setTimeout(() => {
                    setRefresh(prev => !prev)
                    //setShowStripe(false) 
                   setShowStripe(false)
                }, 500)
                //setRefresh(prev=>!prev)
            }).catch(err => {
                //toast.current.show({ severity: "error", summary: "Invoices Updation", detail: "Invoice Updation Failed" });
             setTimeout(() => {
                    setShowStripe(false)
                }, 2000)
            })

        }

    };
    const cardElementOptions = {
        hidePostalCode: false,
        style: {
            base: {
                fontSize: "16px",
                color: "black",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',  
                border: "1px solid #ccc",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    return (
        <>
            <Toast ref={toast} /> 
            
            <form onSubmit={handleSubmit} className="mt-4">
                <CardElement options={cardElementOptions} onReady={handleCardReady}/>
                <Button label="Submit" ref={submitbuttonref} disabled={disableSubmit} className="mt-4 btn">
                </Button>
            </form>
        </>
    );
}
