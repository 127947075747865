import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import React,{useEffect} from "react";
import "./css/plan_configuration.css";
import { useFormik } from "formik"; 
import * as Yup from "yup";  
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";     
 import "./css/editplan.css"
import { useRef } from "react";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { MultiSelect } from "primereact/multiselect";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name Is Required"),
    description: Yup.string().required("Description Is Required"),
    dataAllowance: Yup.number().integer("Please enter a valid integer").required("Data Allowance Is Required "),
    voiceAllowance: Yup.number().integer("Please enter a valid integer").required("Voice Allowance Is Required"),
    textAllowance: Yup.number().integer("Please enter a valid integer").required("Text Allowance Is Required"),
    duration: Yup.number().integer("Please enter a valid integer").required("Duration Is Required"),
    price: Yup.number().required("Price Is Required"),
    type: Yup.string().required("Plan Type Is Required"),
    carrier: Yup.string().required("Carrier Is Required"),
    makeModel:Yup.string().required("Make & Model Is Required"),
    durationUnit: Yup.string().required("Duration Unit Is Required"),

    inventoryType: Yup.string().required("Inventory Type Is Required"),
});
const durationUnitOptions = [
    {
        label: "Days",
        value: "days",
    },
];

export default function EditPlan({ data, setEditPlanVisibility,setRefresh }) {              
    
    const [carrier, setCarrier] = useState(null);
    const [featureOptions, setFeatureOptions] = useState([
        {
            label: "3 Way Calling",
        },
        {
            label: "WI-FI Calling",
        },
        {
            label: "International Roaming",
        },
        {
            label: "5G Compatibility & Tethering Bloc",
        },
    ]);
    const toast = useRef(null);      
       const [isLoading,setIsLoading]=useState(false)
    const loginRes = localStorage.getItem("userData");
const parseLoginRes = JSON.parse(loginRes);
    const [currentBillingId,setCurrentBillingId]=useState("")  
    const [inventoryTypeOptions,setInventoryTypeOptions]=useState([]) 
    const [billingModelOptions,setBillingModelOptions]=useState([])   
      
 
    useEffect(()=>{ 
       //getInventoryList()   
       getBillingModelList()  
    },[])    
    const isUnlimitedOptions = [
        {
            label: "Voice",
        },
        {
            label: "Text",
        },
        { label: "Data" },
    ];     
     
    const formik = useFormik({
        initialValues: {
            name: data.name,
            updatedBy: parseLoginRes?._id,
            serviceProvider: parseLoginRes?.company,
            description: data.description,
            type: data.type,    
            carrier:data?.carrier,    
            dataAllowance: data.dataAllowance,
            voiceAllowance: data.voiceAllowance,
            textAllowance: data.textAllowance,
            duration: data.duration,         
            makeModel:`${data?.make} - ${data?.deviceModel}`,
            price: data.price,  
            isUnlimited: data?.isUnlimited ? data?.isUnlimited :[],
            planId: data.planId,  
            planFeature:data?.planFeature ? data?.planFeature?.filter(item => item.isSelected).map(item => item.name):[], 
            durationUnit: data.durationUnit,
            additionalFeatures: ["calls", "minutes"],
            termsAndConditions: "no termsAndConditions",
            restrictions: ["no restriction"],
            inventoryType: data.inventoryType,
        },
        validationSchema,
        onSubmit: (values) => {   
            setIsLoading(true)      
            let selectedFeatures = [];
            featureOptions.forEach((item) => {
                console.log(item);
                let obj = {
                    name: item?.label,
                    isSelected: formik.values.planFeature.includes(item?.label) ? true : false,
                };
                selectedFeatures.push(obj);
            });       
            let make=formik.values.makeModel.substring(0,formik.values.makeModel.indexOf(" "))   
            let model=formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf(" ")+1)
            let dataTosend = { ...formik.values , planFeature: selectedFeatures, make:make,model:model };     
            delete dataTosend.makeModel
            Axios.patch(`${BASE_URL}/api/web/plan?_id=${data._id}`, dataTosend)
                .then(() => {   
                    
                    toast.current.show({ severity: "success", summary: "Plan Updation", detail: "Plan Updated Successfully" });
                    setIsLoading(false)
                    setTimeout(() => {
                        setEditPlanVisibility(false); 
                        setRefresh(prev=>!prev)
                    }, 2000);
                })
                .catch((err) => { 

                    toast.current.show({ severity: "error", summary: "Plan Updation", detail: err?.response?.data?.msg });
                    setIsLoading(false)
                 });
        },
    });        
    
    const [makeModelOptions,setMakeModelOptions]=useState()  
    useEffect(()=>{ 
        if(formik.values.inventoryType !== "" && currentBillingId !== ""){ 
           Axios.get(`${BASE_URL}/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`).then(res=> { 
            console.log(res?.data?.data)   
            let Data=res?.data?.data      
             let dataNew=[] 
             for(let i=0;i<Data.length; i++){ 
               let data=Data[i] 
               data.makemodel=`${data.make} - ${data.model}` 
               dataNew.push(data)
             }  
             setMakeModelOptions(dataNew)
           }).catch(err=>{ 

           })
           
     
        }
     },[currentBillingId,formik.values.inventoryType]) 
    const getBillingModelList=async () => { 
        try{ 
           const res=await Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
           setBillingModelOptions(res?.data?.data || [])    
           let billingmodel=res?.data?.data   
           let id;
           billingmodel.map((item) => {
               if (item.billingModel === formik.values.type) {
                   id = item._id;
               }
           });
           setCurrentBillingId(id);
             } 
        catch(error){ 
            toast.current.show({ severity: "error", summary: "Plan Updation", detail: error?.response?.data?.msg });
           
        }
    }    
    useEffect( ()=>{           
        async function fetchData(){
        if(formik.values.type !== ""){
            try {
                const res = await Axios.get(`${BASE_URL}/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                let obj = [];
                let data = res?.data?.data;
                data.forEach((item) => {
                    let obj2 = {};
                    obj2.inventoryType = item;
                    obj.push(obj2);
                });
                setInventoryTypeOptions(obj);
            } catch (error) {
                //toast.error(error?.response?.data?.msg);
            } 
        } 
    } 
    fetchData()
     },[currentBillingId])      
     useEffect(() => {
        Axios.get(`${BASE_URL}/api/web/carrier/all`)
            .then((res) => {
                let carrierholder = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    const obj = {};
                    obj.label = res.data.data[i].name;
                    obj.value = res.data.data[i]._id;
                    carrierholder.push(obj);
                    
                }
                setCarrier(carrierholder);
            })
            .catch(() => {});
    }, []);
    return (
          <>
            <div>
                <form onSubmit={formik.handleSubmit} className="maineditplan flex  flex-wrap  flex-row justify-content-start">
                <div className="editplanfield mt-2">
                        <label className="block">
                          Billing Model <span className="star">*</span>
                        </label>
                        <Dropdown placeholder="Plan  Type" options={billingModelOptions} className="  w-full  mt-2" name="type" optionLabel="billingModel" optionValue="billingModel" value={formik.values.type} onChange={(e) => {
                                       
                                        formik.setFieldValue("type", e.value);
                                        let id;
                                        billingModelOptions.map((item) => {
                                            if (item.billingModel === e.value) {
                                                id = item._id;
                                            }
                                        });
                                        setCurrentBillingId(id);
                                    }} />
                        {formik.touched.type && formik.errors.type ? <p className="mt-2 ml-1 star">{formik.errors.type}</p> : null}
                    </div>
                    <div className="editplanfield mt-2">
                        <label className="block">
                            Plan Inventory Type <span className="star">*</span>
                        </label>
                        <Dropdown placeholder="Plan Inventory Type" options={inventoryTypeOptions} optionLabel="inventoryType" optionValue="inventoryType" className="  w-full  mt-2" name="inventoryType" value={formik.values.inventoryType} onChange={formik.handleChange} />
                        {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="mt-2 ml-1 star">{formik.errors.inventoryType}</p> : null}
                    </div>         
                        <div className="editplanfield mt-2">
                        <label className="block">Make And Model</label>
                        <Dropdown optionLabel="makemodel" optionValue="makemodel" value={formik.values.makeModel} name="makeModel" options={makeModelOptions} onChange={formik.handleChange} placeholder="Select an option" className="mt-2 w-full " />      
                        {formik.touched.makeModel && formik.errors.makeModel ? <p className="mt-2 ml-1 star">{formik.errors.makeModel}</p> : null}
                    </div>     
                    <div className="editplanfield mt-2">
                        <label className="block">
                            Carrier <span className="star">*</span>
                        </label>
                        <Dropdown value={formik.values.carrier} options={carrier} optionLabel="label" name="carrier" optionValue="value" onChange={formik.handleChange} placeholder="Select an option" className="w-full mt-2 " />
                        {formik.errors.carrier && formik.touched.carrier && (
                            <div className="mt-2" style={{ color: "red" }}>
                                {formik.errors.carrier}
                            </div>
                        )}
                    </div>
                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Name <span className="star">*</span>
                        </label>
                        <InputText className="  w-full  mt-2" name="name" value={formik.values.name} onChange={formik.handleChange} />
                        {formik.touched.name && formik.errors.name ? <p className="mt-2 ml-1 star">{formik.errors.name}</p> : null}
                    </div>   
                    <div className="editplanfield mt-2">
                        <label className="block">
                            Unlimited <span className="star">*</span>
                        </label>

                        <MultiSelect className="w-full mt-2  " placeholder="Select Unlimited" optionLabel="label" options={isUnlimitedOptions} optionValue="label" name="isUnlimited" onChange={formik.handleChange} value={formik.values.isUnlimited} />
                    </div>
                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Descriptions <span className="star">*</span>
                        </label>
                        <InputText className="  w-full  mt-2" name="description" value={formik.values.description} onChange={formik.handleChange} />
                        {formik.touched.description && formik.errors.description ? <p className="mt-2 ml-1 star">{formik.errors.description}</p> : null}
                    </div>

                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Data Allowance <span className="star">*</span>
                        </label>
                        <InputText keyfilter="int" className="  w-full  mt-2" name="dataAllowance" value={formik.values.dataAllowance} onChange={formik.handleChange} />
                        {formik.touched.dataAllowance && formik.errors.dataAllowance ? <p className="mt-2 ml-1 star">{formik.errors.dataAllowance}</p> : null}
                    </div>
                  
                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Voice Allowance <span className="star">*</span>
                        </label>
                        <InputText keyfilter="int" className="  w-full  mt-2" name="voiceAllowance" value={formik.values.voiceAllowance} onChange={formik.handleChange} />
                        {formik.touched.voiceAllowance && formik.errors.voiceAllowance ? <p className="mt-2 ml-1 star">{formik.errors.voiceAllowance}</p> : null}
                    </div>
                   
                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Text Allowance <span className="star">*</span>
                        </label>
                        <InputText keyfilter="int" className="  w-full  mt-2" name="textAllowance" value={formik.values.textAllowance} onChange={formik.handleChange} />
                        {formik.touched.textAllowance && formik.errors.textAllowance ? <p className="mt-2 ml-1 star">{formik.errors.textAllowance}</p> : null}
                    </div>
                   
                    
                  

                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Duration <span className="star">*</span>
                        </label>
                        <InputText keyfilter="int" className="  w-full  mt-2" name="duration" value={formik.values.duration} onChange={formik.handleChange} />
                        {formik.touched.duration && formik.errors.duration ? <p className="mt-2 ml-1 star">{formik.errors.duration}</p> : null}
                    </div>
                    <div className="editplanfield mt-2">
                        <label className="block">
                          Plan Duration Unit <span className="star">*</span>
                        </label>
                        <Dropdown options={durationUnitOptions} className="  w-full  mt-2" name="durationUnit" value={formik.values.durationUnit} onChange={formik.handleChange} />
                        {formik.touched.durationUnit && formik.errors.durationUnit ? <p className="mt-2 ml-1 star">{formik.errors.durationUnit}</p> : null}
                    </div>     
                    <div className="editplanfield mt-2">
                        <label className="block">Features</label>

                        <MultiSelect className="w-full mt-2  " placeholder="Select Features" optionLabel="label" options={featureOptions} optionValue="label" name="planFeature" onChange={formik.handleChange} value={formik.values.planFeature} />
                    </div>
                    <div className="editplanfield mt-2">
                        <label className="block">
                           Plan Price <span className="star">*</span>
                        </label>
                        <InputText keyfilter="num" className="  w-full  mt-2" name="price" value={formik.values.price} onChange={formik.handleChange} />
                        {formik.touched.price && formik.errors.price ? <p className="mt-2 ml-1 star">{formik.errors.price}</p> : null}
                    </div>
                    <div className="mt-2 w-full flex flex-wrap flex-row justify-content-end ">
                        <Button disabled={isLoading} loading={isLoading} className="field-width" label="Submit" type="Submit" />
                    </div>
                </form>
            </div>
            <Toast ref={toast} />
     </>
    );
}
