import React, { useState } from 'react' 
import BillingNavbar from '../../billing_and_invoices/components/BillingNavbar';
import { Calendar } from 'primereact/calendar'
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { toast } from "react-toastify";
import { FilterMatchMode } from "primereact/api";   
import "./customer_usage.css"
import moment from "moment/moment";
import { Dropdown } from 'primereact/dropdown'
import { redirect } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_BASE_URL;    
const CustomerUsage = () => {   
    const onGlobalFilterValueChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onNameDateEnrollmentIdValueFilter = (e, field) => {
        const value = e.target.value;
        let _filters = { ...filters };
        if (field === "enrollment") {
            _filters["enrollment"].value = value;
            setFilters(_filters);
            setEnrollmentIdFilterValue(value);
        } else if (field === "createdTo") {
            setCreatedDateToFilterValue(e.value);
            const updatedDate = new Date(e.value);
            updatedDate.setDate(updatedDate.getDate() + 1);
            _filters["createdTo"].value = new Date(updatedDate).toISOString();
            setFilters(_filters);
        } else {
            setCreatedDateFilterValue(e.value);
            _filters["createdAt"].value = new Date(e.value).toISOString();
            setFilters(_filters);
        }
    }; 
    
    const [createDateToFilterValue, setCreatedDateToFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enrollment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [enrollmentIdFilterValue, setEnrollmentIdFilterValue] = useState("");
    const [createDateFilterValue, setCreatedDateFilterValue] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const [historyData, setHistoryData] = useState();
    const [isLoading, setIsLoading] = useState();
    const selectedid = localStorage.getItem("selectedId");
    const parseselectedid = JSON.parse(selectedid);

    const validationSchema = Yup.object().shape({
        startDate: Yup.string().required("Please select Date"), 
        endDate: Yup.string().required("Please select Date"),     
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            reportType: "",
            startDate: "",
            endDate: "",
        },
        onSubmit: async (values, actions) => {

            const selectedStartDate = startDate;  
            const selectedendDate = endDate;  
            const formattedStartDate = selectedStartDate ? moment(selectedStartDate).format('YYYY-MM-DD') : '';
            const formattedEndDate = selectedendDate ? moment(selectedendDate).format('YYYY-MM-DD') : '';

            setIsSearch(true);
            const dataToSend = {
                UserId:parseselectedid,
                reportType: formik.values.reportType,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            };
           
            setIsLoading(true);
            try {
                const response = await Axios.post(`${BASE_URL}/api/user/customerHistory`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    setHistoryData(response?.data?.data);
                   
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }

        },
    });

    const options = [
        { label: "Select Type", value: "" },
        { label: "All Incomming Call", value: "purchaseHistory" },
        { label: "Out Going Call", value: "orderHistory" },
        { label: "Text", value: "shipmentHistory" },
        { label: "Data", value: "acStatusHistory" },
       
       
    ];     
     const [dataTypeSelected,setDataTypeSelected]=useState("")     
     const [startDate,setStartDate]=useState("")  
     
     const [endDate,setEndDate]=useState("")
    const header = () => {
        return (
            <div className="flex flex-wrap justify-content-left mt-2 w-full maindiv" style={{ backgroundColor: "white" }}>
                <div className="flex flex-row  justify-content-start w-full flex-wrap  ">
                   <Dropdown
                        style={{width:"30%"}}
                        options={options}      
                        value={dataTypeSelected}
                        onChange={(e) => {  
                            setDataTypeSelected(e.value)
                        }}
                        placeholder="Data Type"
                    />
                    <div
                        className="cal"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "43%",         
                        }}
                    >
                        <span style={{ fontWeight: "400", paddingLeft: "2%" }}>From</span>
                        <Calendar
                            value={startDate}
                            dateFormat="mm/dd/yy"
                            // placeholder="Start Date"
                            onChange={(e) => {        
                                setStartDate(e.value)
                            }}
                            showIcon
                            style={{ height: "30px", width: "40%" }}
                        />
                        <span style={{ fontWeight: "400" }}>To</span>
                        <Calendar
                            value={endDate}
                            dateFormat="mm/dd/yy"
                            // placeholder="End Date"
                            onChange={(e) => { 
                                
                                setEndDate(e.value)
                            }}
                            showIcon
                            style={{ height: "30px", border: "none", width: "40%", color: "red" }}
                        />
                    </div>

                    {/* <div className="w-20rem ml-4 mt-2">
                        <label className="p-2" style={{ fontSize: "19px", textAlign: "center", color: "grey" }}>
                            To
                        </label>
                    </div> */}
                </div>
            </div>
        );
    };
  return (
    <>
<div className="customerusagemain card"> 
       <div className='mt-4'>
<BillingNavbar/>    
</div>
                            <h3 className="font-bold">Customer Usage </h3>
                            <DataTable value={historyData}  size="small"
                            stripedRows
                            resizableColumns  paginator
                            rows={10}  
                             header={header}
                              
                              >
                                <Column header="#" field="historyData.firstName"  />
                                <Column header="Date" field="historyData.firstName"  />
                                <Column header="Type" field="NahistoryDatame.firstName"  />
                                <Column header="MDN" field="historyData.firstName"  />
                                <Column header="Called/Calling Number" field="historyData.firstName"  />
                                <Column header="Forwarded Number" field="historyData.firstName"  />
                                <Column header="Used Units" field="NahistoryDatame.firstName"  />
                                <Column header="Billable Units" field="historyData.firstName"  />
                                <Column header="Call Type" field="historyData.firstName"  />
                                <Column header="Originating Newtwork Type" field="historyData.firstName"  />
                                <Column header="Destination Class" field="historyData.firstName"  />
                                <Column header="Plan Code" field="historyData.firstName"  />
                                <Column header="Data Type" field="historyData.firstName"  />
                             </DataTable>
                            
                        </div>
    </>
  )
}

export default CustomerUsage